<script>
import { mapGetters } from "vuex";
import { stringSplit } from "@/utils/helpers";
import _deburr from "lodash/deburr";

export default {
  props: {
    active: Boolean,
    value: String,
  },

  data() {
    return {
      search: "",
      selected: this.getSelected(),
    };
  },

  computed: {
    ...mapGetters("config", [
      "config_locations_groups",
      "config_locations_full",
    ]),
    filteredLocations() {
      if (!this.search.length) {
        return this.config_locations_groups;
      }
      let locations = this.config_locations_groups;
      let search = _deburr(this.search.toLowerCase());
      let groups = [];
      for (let group = 0; group < locations.length; group++) {
        let items = [];
        let max = 30;
        if (locations[group].group_name == "provinces") max = 4;
        else if (locations[group].group_name == "cities") max = 4;
        for (
          let loc = 0;
          loc < locations[group].group_items.length && items.length < max;
          loc++
        ) {
          if (
            _deburr(
              locations[group].group_items[loc].loc_name_long.toLowerCase(),
            ).indexOf(search) > -1
          ) {
            items.push(locations[group].group_items[loc]);
          }
        }
        if (items.length) {
          groups.push({
            group_items: items,
            group_label: locations[group].group_label,
            group_name: locations[group].group_name,
          });
        }
      }
      return groups;
    },
    selectedLocations() {
      let selectedLocations = [];
      let locations = this.config_locations_full;
      for (let index = 0; index < locations.length; index++) {
        if (this.selected.indexOf(locations[index].loc_id) > -1) {
          selectedLocations.push(locations[index]);
        }
      }
      return selectedLocations;
    },
  },

  methods: {
    apply() {
      this.$emit("input", this.selected.toString());
    },
    exit() {
      this.$emit("input", this.value);
    },
    getSelected() {
      let locations = stringSplit(this.value, ",");
      for (let index = 0; index < locations.length; index++) {
        locations[index] = Number(locations[index]);
      }
      return locations;
    },
    remove(term) {
      let index = this.selected.indexOf(term.loc_id);
      if (index !== -1) this.selected.splice(index, 1);
    },
    searchChange(value) {
      this.search = value;
    },
    add(term) {
      this.search = "";
      this.selected.push(term.loc_id);
    },
    focusInput() {
      this.$nextTick(() => {
        let searchInput = this.$el.querySelectorAll(".fieldLocations .input");
        if (searchInput.length) searchInput[0].focus();
      });
    },
    inputBlur() {
      let searchInput = this.$el.querySelectorAll(".fieldLocations .input");
      if (searchInput.length) searchInput[0].blur();
    },
  },

  watch: {
    $route() {
      this.selected = this.getSelected();
    },
    active(active) {
      this.search = "";
      if (active) {
        this.focusInput();
      } else {
        this.inputBlur();
      }
    },
  },
};
</script>

<template>
  <div class="finderMobileFilterView" :class="{ active }">
    <div class="filters__bar">
      <form class="fieldLocations" @submit.prevent="inputBlur">
        <!-- <span class="sicon-search" /> -->
        <span class="icon" @click="exit()">
          <i class="fas fa-times"></i>
        </span>
        <input
          class="input"
          type="text"
          placeholder='Filtra ubicaciones, ej: "santo domingo"'
          v-model="search"
        />
      </form>
    </div>
    <template v-if="!search.length">
      <div class="filters__body" v-if="!selectedLocations.length">
        <div class="none-selected">
          <span>No has seleccionado ninguna ubicación.</span>
        </div>
      </div>
      <div class="filters__body listTerms" v-else>
        <div class="group__title">Seleccionadas</div>
        <div
          class="term"
          v-for="term in selectedLocations"
          :key="term.loc_id"
          @click="remove(term)"
        >
          <span class="name">{{ term.loc_name_long }}</span>
          <span class="has-text-danger">Remover</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="filters__body listTerms">
        <div v-for="group in filteredLocations" :key="group.group_name">
          <div class="group__title">{{ group.group_label }}</div>
          <template v-for="term in group.group_items">
            <div
              v-if="selected.indexOf(term.loc_id) > -1"
              class="term"
              :key="term.loc_id"
              @click="remove(term)"
            >
              <span class="name">{{ term.loc_name_long }}</span>
              <span class="has-text-danger">Remover</span>
            </div>
            <div v-else class="term" :key="term.loc_id" @click="add(term)">
              <span class="name">{{ term.loc_name_long }}</span>
              <span class="has-text-link">Agregar</span>
            </div>
          </template>
        </div>
      </div>
    </template>
    <div class="filters__footer">
      <b-button rounded class="btn--finish" type="is-primary" @click="apply()"
        >Aplicar filtros</b-button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.listTerms {
  padding: 0;
  padding-bottom: 1rem;
}
.none-selected {
  padding-top: 10%;
  text-align: center;
}
.filters__bar {
  padding: 1rem 1.5rem;
}
.fieldLocations {
  position: relative;
  .icon,
  .sicon-search,
  .input {
    height: 2.75rem;
  }
  .icon,
  .sicon-search {
    position: absolute;
    z-index: 1;
    left: 0.375rem;
    width: 2.25rem;
  }
  .input {
    padding: 0 1rem;
    padding-left: 2.625rem;
    border: 1px solid #dbdbdb;
    border-radius: 1.5rem;
    background-color: #f2f2f2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.group__title,
.term {
  padding: 0 1.5rem;
}
.group__title {
  background-color: #eee;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  position: sticky;
  top: 0;
  font-weight: bold;
}
.term {
  font-size: 0.875rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 1rem;
  }
}
</style>
